
import React from "react";
import { Button } from "../../form/Button";
import * as moduleStyles from "./Header.module.scss";

export default function Menu(props) {
  const {
    desktop,
    onAfterClick,
  } = props;

  const linkProps = {
    className: desktop ? "text-small grey-300" : null,
    theme: Button.THEMES.DARK,
    size: Button.SIZES.LARGE,
    link: desktop ? true : null,
    onClick: onAfterClick,
  }
  const buttonProps = {
    size: desktop ? Button.SIZES.MEDIUM : Button.SIZES.LARGE,
  }

  return (
    <>
      <div className={moduleStyles.menuLinks}>
        <Button to="/#reviews" title="Reviews" {...linkProps}>Reviews</Button>
        <Button to="/#features" title="Features" {...linkProps}>Features</Button>
        <Button to="/#examples" title="Discover" {...linkProps}>Discover</Button>
        <Button to="/#pricing" title="Blog" {...linkProps}>Pricing</Button>
        <Button to="https://blog.bravemark.co/" title="Blog" {...linkProps}>Blog</Button>
      </div>
      <div className={moduleStyles.menuButtons}>
        <Button to="https://app.bravemark.co/" label="Sign In" secondary {...buttonProps} />
        <Button to={process.env.GATSBY_REGISTRATION_URL} label="Start for Free" {...buttonProps} />
      </div>
    </>
  );
}