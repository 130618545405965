import React from "react";
import * as moduleStyles from "./Footer.module.scss";
import instagram from "../../../images/instagram-fill.svg";
import twitter from "../../../images/twitter-fill.svg";
import linkedin from "../../../images/linkedin-fill.svg";
import youtube from "../../../images/youtube-fill.svg";
import Conditional from "../../conditional/Conditional";
import Spacer from "../../spacer/Spacer";
import Logo from "../../icons/Logo";
import useWindowSize from "../../../hooks/useWindowSize";
import { Button } from "../../form/Button";

const MOBILE_CUTOFF = 1300;

export default function Footer(props) {
  const windowSize = useWindowSize();

  return (
    <div className={moduleStyles.container}>
      {windowSize.width > MOBILE_CUTOFF ? <Desktop {...props} /> : <Mobile {...props} /> }
    </div>
  )
}

Footer.defaultProps = {
  showPrivacySettings: true,
}


function Desktop(props) {
  const innerClasses = [
    moduleStyles.inner,
    moduleStyles.desktop,
  ]

  return (
    <>
      <div className={innerClasses.join(" ")}>
        <TextualLinks {...props} />
        <Copyright />
        <SocialLinks />
      </div>
      <Spacer amount="60" />
    </>
  )
}

function Mobile(props) {
  const innerClasses = [
    moduleStyles.inner,
    moduleStyles.mobile,
  ]

  return (
    <>
      <div className={innerClasses.join(" ")}>
        <Copyright />
        <SocialLinks />
        <TextualLinks {...props} />
      </div>
      <Spacer amount="25" />
    </>
  )
}

function Copyright() {
  return (
    <div className={moduleStyles.copyright}>
      <div className={moduleStyles.logo}><Logo /></div>
      <div className={moduleStyles.brand}>Bravemark Inc &copy; {new Date().getFullYear()}</div>
    </div>
  )
}

function SocialLinks() {
  return (
    <div className={moduleStyles.nav + " " + moduleStyles.socialLinks}>
      <Button link to="https://www.youtube.com/@hellobravemark" title="Youtube">
        <img src={youtube} alt="Youtube" />
      </Button>
      <Button link to="https://instagram.com/hellobravemark" title="Instagram">
        <img src={instagram} alt="Instagram" />
      </Button>
      <Button link to="https://twitter.com/hellobravemark" title="Twitter">
        <img src={twitter} alt="Twitter" />
      </Button>
      <Button link to="https://linkedin.com/company/bravemark" title="LinkedIn">
        <img src={linkedin} alt="Linkedin" />
      </Button>
    </div>
  )
}

function TextualLinks(props) {
  return (
    <div className={moduleStyles.nav + " " + moduleStyles.textualLinks}>
      <Button link to="/privacy-policy" title="Privacy Policy">Privacy Policy</Button>
      <Button link to="/terms-conditions" title="Terms of Use">Terms of Use</Button>
      <Conditional show={props.showPrivacySettings}>
        <Button link onClick={props.onPrivacySettingsClick}>Cookies</Button>
      </Conditional>
    </div>
  )
}